
import React, { useState } from 'react';
import logo from '../../assets/logo.png';
import pdf from '../../assets/CITY CAT COMPANY Product List.pdf'
import { Link } from 'react-router-dom';


const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleProductDropdown = () => {
        setIsProductDropdownOpen(!isProductDropdownOpen);
    };

    return (
<nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
  <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-60">
    <div className="relative flex items-center justify-between h-28">
      <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <button
          type="button"
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-800"
          aria-controls="mobile-menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
          <svg
            className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <a href="/" className="text-gray-800 text-7xl font-bold flex items-center space-x-2">
              <img src={logo} alt="Logo" className="h-20 w-auto" />
            </a>
          </div>
          <div className="hidden sm:block text-gray-800 mt-5 text-base font-medium">
            Your Vision. Our Future<br/>
            <span className="text-slate-400 text-sm font-semibold">
              meeting our needs
            </span>
          </div>
        </div>
        <div className="hidden sm:block md:ml-96 sm:ml-14 pt-4">
          <div className="flex space-x-4">
            <Link
              to="/"
              className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 px-3 py-2 rounded-md text-lg font-medium"
            >
              Home
            </Link>
            <div className="relative">
              <button
                onClick={toggleProductDropdown}
                className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 px-3 py-2 rounded-md text-lg font-medium flex items-center"
              >
                Products
                <svg
                  className="ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {isProductDropdownOpen && (
                <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <Link
                      to="/compressors"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Compressors
                    </Link>
                    <Link
                      to="/valves"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Valves
                    </Link>
                    <Link
                      to="/pumps"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Pumps
                    </Link>
                    <Link
                      to="/fasterners"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Fasterners
                    </Link>
                    <Link
                      to="/flowmeter"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Flowmeter
                    </Link>
                    <Link
                      to="/transmitter"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                     Transmitter
                    </Link>
                    <Link
                      to="/boilers"
                      className="block px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 text-center"
                      role="menuitem"
                    >
                      Boilers
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <a
              href={pdf}
              className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 px-3 py-2 rounded-md text-lg font-medium"
              download
            >
              Download Brochure
            </a>
            <Link
              to="/testimonials"
              className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 px-3 py-2 rounded-md text-lg font-medium"
            >
              Testimonials
            </Link>
            <Link
              to="/contact"
              className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 px-3 py-2 rounded-md text-lg font-medium"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
    <div className="px-2 pt-2 pb-3 space-y-1">
      <Link
        to="/"
        className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium text-center"
      >
        Home
      </Link>
      <div className="relative">
        <button
          onClick={toggleProductDropdown}
          className="w-full text-center text-gray-800 hover:bg-gray-200 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium"
        >
          Products
          <svg
            className="ml-2 h-5 w-5 inline"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        {isProductDropdownOpen && (
          <div className="mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <Link
                to="/compressors"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Compressors
              </Link>
              <Link
                to="/valves"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Valves
              </Link>
              <Link
                to="/pumps"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Pumps
              </Link>
              <Link
                to="/fasterners"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Fasterners
              </Link>
              <Link
                to="/flowmeters"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Flowmeters
              </Link>
              <Link
                to="/transmitter"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Transmitter
              </Link>
              <Link
                to="/boilers"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-center"
                role="menuitem"
              >
                Boilers
              </Link>
            </div>
          </div>
        )}
      </div>
      <a
        href={pdf}
        className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium text-center"
      >
        Download Brochure
      </a>
      <Link
        to="/testimonials"
        className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium text-center"
      >
        Testimonials
      </Link>
      <Link
        to="/contact"
        className="text-gray-800 hover:bg-gray-200 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium text-center"
      >
        Contact Us
      </Link>
    </div>
  </div>
</nav>

    );
};

export default Navbar;
