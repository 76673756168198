import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import centrifugal from "../../assets/Centrifugal Pump.jpg";
import gear from "../../assets/Gear Pump.jpg";
import booster from "../../assets/Booster Pump.jpg";
import screw from "../../assets/Screw Pump.jpg";
import plunger from "../../assets/Plunger Pump.jpg";
import submersal from "../../assets/Submersible Pump.jpg";
import metering from "../../assets/Metering Pump.jpg";
import diaphragm from "../../assets/Diaphragm Pump.jpg";
import sand from "../../assets/Sand Pump.jpg";
import hose from "../../assets/Pressure Hose.jpg";
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";

const Pumps: React.FC = () => {
   

    return (
     
      <><Helmet>
        <title>Pumps | City Cat International</title>
        <meta name="description" content="Explore various types of pumps including centrifugal, gear, booster, screw, plunger, submersible, metering, diaphragm, sand, and pressure hose pumps. Get quotes now!" />
        <meta name="keywords" content="centrifugal pump, gear pump, booster pump, screw pump, plunger pump, submersible pump, metering pump, diaphragm pump, sand pump, pressure hose" />
        <link rel="icon" href={logo} type="image/x-icon" />
      </Helmet><><Navbar></Navbar>

          <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase ">Pumps</h2>
          <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={centrifugal}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Centrifugal Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Centrifugal pumps are used to transport fluids by the
                    conversion of rotational kinetic energy to the hydrodynamic
                    energy of the fluid flow. The rotational energy typically
                    comes from an engine or electric motor.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={gear}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Gear Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A gear pump is a type of positive displacement
                    (PD) pump. It moves a fluid by repeatedly enclosing
                    a fixed volume using interlocking cogs or gears,
                    transferring it mechanically using a cyclic pumping action.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={booster}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Booster Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A booster pump is a high-pressure pump which has an
                    Impeller inside which improves its water flow and
                    pressure. Most water pressure pumps consist of a pump,
                    inlet and outlet, impeller, and a pressure monitoring device.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={screw}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Screw Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A screw pump, also known as a water screw, is a
                    positive-displacement pump that use one or several
                    screws to move fluids or solids along the screw axis.
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={diaphragm}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Diaphragm Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A diaphragm pump (also known as a Membrane pump)
                    is a positive displacement pump that uses a
                    combination of the reciprocating action of a rubber,
                    thermoplastic or Teflon diaphragm.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>




          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={plunger}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Plunger Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A plunger pump is a type of positive displacement pump
                    where the high-pressure seal is stationary and a smooth
                    cylindrical plunger slides through the seal.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={submersal}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Submersible Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Submersible pumps are often used to pump
                    Excess water from work sites or flooded
                    basements in construction sites. They can also be
                    used to pump slurries
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={metering}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Metering Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A metering pump moves a precise volume of liquid in a
                    specified time providing an accurate volumetric
                    flow rate. Delivery of fluids in precise adjustable flow
                    rates is sometimes called metering.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={sand}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Sand Pump</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Sand pumps address the need of moving deposits away from the drilling site. Sand pumps are most often placed in oil or other fluid tanks that have filled with sand.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>


          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={hose}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">High Pressure Hose</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                  High pressure hoses - the most important part of modern equipment, are widely used for all hydraulic systems, gas, and water supply to deliver fluids under pressure.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>  
                </div>
              </div>
            </div>
          </section>




          <Footer></Footer></></>
    )
};

export default Pumps;
