import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import fieldbus from '../../assets/Absolute Pressure Transmitter.png';
import diffpresure from '../../assets/Differential Pressure Transmitter.png';
import AbsolutePressure from '../../assets/Absolute Pressure Transmitter.png';
import guage from '../../assets/Gauge Pressure.jpg';
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";

const Transmitter: React.FC = () => {
    return (
      <>
        <Helmet>
          <title>Transmitters - City Cat International</title>
          <meta name="description" content="Explore a variety of transmitters including Gauge Pressure, Absolute Pressure, Differential Pressure, and Fieldbus transmitters at City Cat International." />
          <meta name="keywords" content="Gauge Pressure Transmitter, Absolute Pressure Transmitter, Differential Pressure Transmitter, Fieldbus Transmitter, City Cat International, transmitter manufacturers, industrial transmitters, oil and gas transmitters, transmitter parts suppliers, transmitter servicing" />
          <link rel="icon" href={logo} />
        </Helmet>
        <Navbar />
        
        <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Transmitter</h2>
        <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

        <section data-aos="fade-right">
          <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                <img
                  alt="Gauge Pressure Transmitter"
                  src={guage}
                  className="absolute inset-0 h-full w-full object-contain" />
              </div>
              <div className="lg:py-24">
                <h2 className="text-5xl text-center font-bold sm:text-5xl">Gauge Pressure Transmitter</h2>
                <p className="mt-4 text-gray-600 text-xl text-justify">
                  A gauge pressure (GP) transmitter compares a process pressure against local ambient air pressure. Example Industrial Applications: Open tank level, Line pressure, and Blanket pressure in a tank.
                </p>
                <div className="mt-8 flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section data-aos="fade-left">
          <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                <img
                  alt="Absolute Pressure Transmitter"
                  src={AbsolutePressure}
                  className="absolute inset-0 h-full w-full object-contain" />
              </div>
              <div className="lg:py-24">
                <h2 className="text-center text-5xl font-bold sm:text-5xl">Absolute Pressure Transmitter</h2>
                <p className="mt-4 text-gray-600 text-xl text-justify">
                  Absolute pressure (AP) transmitter measures relative to perfect (full) vacuum pressure (absolute zero pressure); therefore, AP transmitters are not affected by fluctuations in the local atmospheric pressure. All absolute pressure measurements are positive.
                </p>
                <div className="mt-8 flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section data-aos="fade-right">
          <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                <img
                  alt="Differential Pressure Transmitter"
                  src={diffpresure}
                  className="absolute inset-0 h-full w-full object-contain" />
              </div>
              <div className="lg:py-24">
                <h2 className="text-5xl text-center font-bold sm:text-5xl">Differential Pressure Transmitter</h2>
                <p className="mt-4 text-gray-600 text-xl text-justify">
                  Differential Pressure (DP) transmitters measure the difference between two pressures. They use a reference point called the low-side pressure and compare it to the high-side pressure. Ports in the instrument are marked high-side and low-side.
                </p>
                <div className="mt-8 flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section data-aos="fade-left">
          <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
              <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                <img
                  alt="Fieldbus Transmitters"
                  src={fieldbus}
                  className="absolute inset-0 h-full w-full object-contain" />
              </div>
              <div className="lg:py-24">
                <h2 className="text-5xl text-center font-bold sm:text-5xl">Fieldbus Transmitters</h2>
                <p className="mt-4 text-gray-600 text-xl text-justify">
                  Fieldbus transmitters are essential components in the oil and gas industry, providing advanced digital communication capabilities for efficient and reliable process control. These transmitters use digital fieldbus protocols to transmit data, allowing for enhanced diagnostics, configuration, and control capabilities compared to traditional analog transmitters.
                </p>
                <div className="mt-8 flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
};

export default Transmitter;
