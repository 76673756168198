import Footer from "../footer/footer";
import Navbar from "../navbar/navbar"; 
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";
import turbine from '../../assets/Turbine Flowmeter.png';
import helix from '../../assets/Helix Rotor Flow meter.png';
import corolis from '../../assets/Coriolis Mass Flow meter.png';
import oval from '../../assets/oval gear.jpg';

const Flowmeter: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Flowmeters - City Cat International</title>
                <meta name="description" content="Explore a variety of flowmeters including Turbine, Helix Rotor, Coriolis Mass, and Oval Gear Flowmeters at City Cat International." />
                <meta name="keywords" content="Turbine Flowmeter, Helix Rotor Flowmeter, Coriolis Mass Flowmeter, Oval Gear Flowmeter, City Cat International, flowmeter manufacturers, industrial flowmeters, oil and gas flowmeters, flowmeter parts suppliers, flowmeter servicing" />
                <link rel="icon" type="image/png" href="/path/to/your/favicon.png" />
                <link rel="icon" href={logo} />
            </Helmet>
            <Navbar />
            <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Flowmeter</h2>
            <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

            <section data-aos="fade-right">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                            <img
                                alt="Turbine Flowmeter"
                                src={turbine}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-5xl text-center font-bold sm:text-5xl">Turbine Flowmeter</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Turbine flowmeters are widely used in the oil and gas industry for measuring the flow rate of liquids and gases. They offer high accuracy, reliability, and are suitable for various applications within the industry. A turbine flowmeter measures the flow rate of a fluid by using a rotor (turbine) placed in the flow stream. The speed of the rotor's rotation is proportional to the velocity of the fluid passing through the meter, which can be translated into a flow rate.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-left">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                            <img
                                alt="Helix Rotor Flowmeter"
                                src={helix}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-center text-5xl font-bold sm:text-5xl">Helix Rotor Flowmeter</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Helix rotor flowmeters, also known as helical turbine flowmeters or axial turbine flowmeters, are highly specialized instruments used in the oil and gas industry for accurate measurement of fluid flow, especially in challenging conditions. They are known for their high precision, reliability, and capability to handle a variety of fluid types and flow conditions. Helix rotor flowmeters utilize a helical or screw-type rotor positioned in the flow stream. The fluid flow causes the rotor to spin, and the rotational speed is directly proportional to the flow rate. This type of flowmeter is particularly suitable for applications involving high-viscosity fluids and varying flow rates.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-right">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                            <img
                                alt="Oval Gear Flowmeter"
                                src={oval}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-5xl text-center font-bold sm:text-5xl">Oval Gear Flowmeter</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Oval gear flowmeters are a type of positive displacement flowmeter used extensively in the oil and gas industry for precise measurement of fluid flow. These meters are particularly valued for their accuracy and reliability, especially in applications involving viscous fluids. Oval gear flowmeters measure the flow rate by counting the number of rotations of oval-shaped gears, which are driven by the fluid passing through the meter. Each rotation represents a specific volume of fluid, allowing for highly accurate flow measurement.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-left">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                            <img
                                alt="Coriolis Mass Flowmeter"
                                src={corolis}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-5xl text-center font-bold sm:text-5xl">Coriolis Mass Flowmeter</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Coriolis mass flowmeters are used predominantly in scientific applications where they measure both corrosive and clean gases and liquids.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Flowmeter;
