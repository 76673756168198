import { Helmet } from 'react-helmet';
import Footer  from '../footer/footer';
import Navbar from '../navbar/navbar';
import logo from '../../assets/logo.png';
import React from "react";

const contact: React.FC = () => {
   

    return (
      <><Helmet>
             <title>CityCat |Testimonials</title>
        <meta name="description" content="Read what our customers have to say about CityCat's products." />
        <meta name="keywords" content="CityCat, testimonials, reviews, products, compressors, pumps, valves, fasteners" />
        <link rel="icon" href={logo} />
    </Helmet>
        <><Navbar></Navbar>

        

<div className="text-center w-full">
    <svg className="text-gray-100 h-8 mx-auto"  viewBox="0 0 150 29" version="1.1"
      xmlns="http://www.w3.org/2000/svg" >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-HD" transform="translate(-1112.000000, -438.000000)">
          <g id="logo-4" transform="translate(1112.000000, 438.000000)">
            <path
              d="M76.6605841,24.316569 L76.6605841,6.43875952 L94.3451636,17.7743071 C94.7005374,18.0021643 95.1070794,18.1154024 95.5136215,18.1154024 C95.9201636,18.1154024 96.3267056,18.0021643 96.6820794,17.7743071 L114.366659,6.43875952 L114.366659,24.316569 L76.6605841,24.316569 Z M109.288388,4.65802143 L95.5136215,13.4871405 L81.7388551,4.65802143 L109.288388,4.65802143 Z M114.366659,0.437140476 L76.6605841,0.437140476 L74.5178271,0.437140476 L72.375771,0.437140476 L72.375771,24.316569 L72.375771,28.53745 L76.6605841,28.53745 L114.366659,28.53745 L118.651472,28.53745 L118.651472,24.316569 L118.651472,0.437140476 L116.509416,0.437140476 L114.366659,0.437140476 Z"
              id="Fill-76"></path>
            <path
              d="M10.224743,18.2356143 L14.5039486,6.60247143 L18.7831542,18.2356143 L10.224743,18.2356143 Z M10.8415654,0.847352381 L0.225911215,27.8822571 L6.89179907,27.8822571 L8.6196028,23.3023286 L20.3875935,23.3023286 L22.074743,27.8822571 L28.7406308,27.8822571 L18.1656308,0.847352381 L10.8415654,0.847352381 Z"
              id="Fill-77"></path>
            <path
              d="M51.8908879,5.50827381 C54.7303738,5.50827381 57.1990654,7.25103571 58.3507009,9.43984524 L63.3707944,7.0079881 C61.4369159,3.6439881 57.9392523,0.401511905 51.8908879,0.401511905 C43.661215,0.401511905 37.2834112,6.07584524 37.2834112,14.3850357 C37.2834112,22.6942262 43.661215,28.3685595 51.8908879,28.3685595 C57.9392523,28.3685595 61.3955607,25.0853452 63.3707944,21.7213452 L58.3507009,19.3302262 C57.1990654,21.5590833 54.7303738,23.2617976 51.8908879,23.2617976 C46.9121495,23.2617976 43.2911215,19.4917976 43.2911215,14.3850357 C43.2911215,9.27758333 46.9121495,5.50827381 51.8908879,5.50827381"
              id="Fill-78"></path>
            <polygon id="Fill-79"
              points="149.674276 5.91365238 149.674276 0.846938095 130.25278 0.846938095 130.25278 27.8825333 149.674276 27.8825333 149.674276 22.815819 136.095771 22.815819 136.095771 16.6954381 149.386192 16.6954381 149.386192 11.6287238 136.095771 11.6287238 136.095771 5.91365238">
            </polygon>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div
    className="relative max-w-screen-xl mt-24 px-8 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto  text-gray-900 sm:rounded-lg">
    <div className="absolute right-0 bottom-0 hero-pattern w-64 h-56"></div>
    <div className="relative">
      <div>
        <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight uppercase">Testimonials</h2>
        <div className="my-4 mx-auto w-12 h-2 border-4 border-rose-600"></div>
        <div className="text-center text-gray-700 font-light">Here are what some of our amazing customers are
          saying ...</div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:mx-12">
        <div
          className="relative mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
          We've been using the CityCat Rotary Screw Compressor in our manufacturing plant , and it's been fantastic. It offers impressive energy efficiency, which has significantly reduced our electricity costs. The compressor is robust, reliable, and requires minimal maintenance, leading to less downtime and lower maintenance expenses.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Mohammed Khan</div>
                
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
          The CityCat Centrifugal Compressor has greatly improved our industrial operations with its reliable performance. Its efficient compression capabilities ensure a steady supply of compressed air for our applications. The compressor's durable design and compact size have been particularly beneficial, saving both space and maintenance costs. Overall, it's a highly recommended solution for businesses in need of a dependable compressed air system.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Ahmed Faisal</div>
            
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
The CityCat Centrifugal Pump has been a game-changer for our manufacturing facility, delivering exceptional performance and reliability. Its high efficiency ensures consistent fluid flow while minimizing energy consumption, reducing operational costs significantly. Built with top-quality materials, it's remarkably durable and requires minimal maintenance, resulting in decreased downtime and servicing costs. Operating quietly, it has improved our work environment, and its versatility makes it suitable for various applications. I highly recommend the CityCat Centrifugal Pump to any business seeking a reliable pumping solution.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Yassin Faisal</div>
            
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
          We've been using both the CityCat Globe Valve and Butterfly Valve in our industrial operations, and they have both performed exceptionally well. The Globe Valve excels in precise flow control, durability, and easy maintenance, though it has a higher cost and causes a pressure drop. The Butterfly Valve is efficient, cost-effective, and easy to install, with low pressure drop but less precise control and potential seal wear. Both valves are valuable assets, with the Globe Valve standing out for precision and the Butterfly Valve for cost-efficiency.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Smith Johnson</div>
            
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
          The fasteners provided by CityCat have been instrumental in our manufacturing processes, offering exceptional quality and reliability. These fasteners have consistently met our expectations, providing secure and durable connections in various applications. Their precision engineering ensures a perfect fit, minimizing the risk of loosening or failure over time. Additionally, CityCat's fasteners are easy to install, saving us valuable time and labor costs. Overall, we are highly satisfied with the performance of CityCat's fasteners and consider them an essential component of our operations.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Osman Ali</div>
            
              </div>
            </div>
          </div>
        </div>
        <div
          className="mx-auto mt-8 rounded-lg shadow max-w-sm p-10 bg-gray-100 text-gray-700 leading-snug flex flex-col justify-between">
          <div className="-ml-4">
            <svg className="w-8 opacity-25 text-indigo-500" xmlns="http://www.w3.org/2000/svg"
              shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0
                                                                                                             640 640"
              >
              <path
                d="M557.133 561.704H442.128c-44.256 0-80.458-36.19-80.458-80.446 0-165.58-42.32-347.485 160.656-399.418 91.95-23.516 115.915 77.753 18.119 84.745-59.647 4.276-71.293 42.804-73.147 101.068h92.269c44.256 0 80.458 36.201 80.458 80.458v130.702c0 45.591-37.3 82.89-82.891 82.89zm-358.032 0H84.096c-44.256 0-80.446-36.19-80.446-80.446 0-165.58-42.331-347.485 160.644-399.418 91.95-23.516 115.915 77.753 18.118 84.745-59.646 4.276-71.292 42.804-73.146 101.068h92.269c44.256 0 80.457 36.201 80.457 80.458v130.702c0 45.591-37.3 82.89-82.89 82.89z" />
            </svg>
          </div>
          <div className="mt-2">
          Since implementing the CityCat Submersible Pump into our industrial processes, we've witnessed remarkable improvements in efficiency and reliability. This pump has seamlessly handled pumping fluids from submerged environments, streamlining our operations. Its durable construction and compact design have made installation and maintenance hassle-free. Additionally, its energy-efficient performance has contributed to cost savings, while its quiet operation has enhanced our work environment. Overall, the CityCat Submersible Pump has exceeded our expectations and is an invaluable asset for businesses requiring reliable fluid pumping from submerged areas.
          </div>
          <div>
            <div className="mx-auto w-full border border-gray-300 my-8"></div>
            <div className="flex items-center">
              
              <div className="ml-4">
                <div className="font-bold">Hafiz Haider Ali</div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
     


</><Footer></Footer></>

    )
};

export default contact;
