
import { GrMapLocation } from 'react-icons/gr';
import Footer  from '../footer/footer';
import Navbar from '../navbar/navbar'
import { IoCallOutline } from 'react-icons/io5';
import { CgMail } from 'react-icons/cg';
import { FaWhatsapp } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import logo from '../../assets/logo.png';
import React from "react";


const contact: React.FC = () => {
   

    return (
        <><Helmet>
        <title>Contact Us - CityCat International Ltd.</title>
        <meta name="description" content="Get in touch with CityCat International Ltd. for all your queries and support needs. Find our contact information including address, phone number, email, and WhatsApp details." />
        <meta name="keywords" content="CityCat, Contact Us, Support, Address, Phone Number, Email, WhatsApp" />
        <link rel="icon" href={logo} />
        </Helmet>
        <><Navbar></Navbar>

        
        <section
            className="relative z-10 overflow-hidden bg-white dark:bg-dark py-20  mt-24 lg:py-[120px]"
        > <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight uppercase">Contact Us</h2>
     <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>
    <div className="container mx-auto">
        <div className="flex flex-wrap -mx-4 lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12 flex justify-center items-center">
                <div className="mb-12 max-w-[570px] lg:mb-0">
                    <h2 className="text-dark dark:text-white mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[32px] xl:text-[35px]">
                        GET IN TOUCH WITH US
                    </h2>
                    <div className="mb-8 flex w-full max-w-[370px] ">
                        <div className="text-gray-600 mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded sm:h-[70px] sm:max-w-[70px]">
                            <div className="text-3xl mb-2">
                                <GrMapLocation />
                            </div>
                        </div>
                        <div className="w-full">
                            <h4 className="mb-1 text-2xl font-semibold text-dark underline dark:text-white">
                                Address
                            </h4>
                            <p className="text-base text-body-color text-center font-semibold dark:text-dark-6">
                                <a href="https://www.google.co.in/maps/place/KK+Chambers,+Dr+Dadabhai+Naoroji+Rd,+Azad+Maidan,+Fort,+Mumbai,+Maharashtra+400001/@18.935806,72.8306484,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7d1dc0d3b42ef:0xbb4045be9a75e71f!8m2!3d18.935806!4d72.8332233!16s%2Fg%2F1ptw_nts7?entry=ttu">
                                    City Cat International Ltd., 4th floor, K.K Chambers, Purshottamdas Thakur Marg, Fort, Mumbai - 400001, India
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="mb-8 flex w-full max-w-[370px]">
                        <div className="text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded sm:h-[70px] sm:max-w-[70px]">
                            <div className="text-4xl mb-2">
                                <IoCallOutline />
                            </div>
                        </div>
                        <div className="w-full">
                            <h4 className="mb-1 text-2xl font-semibold text-dark underline dark:text-white">
                                Pan India No
                            </h4>
                            <p className="text-base text-body-color font-semibold dark:text-dark-6">
                                <a href="tel:+919819600149">+91 9819600149</a>
                            </p>
                        </div>
                    </div>
                    <div className="mb-8 flex w-full max-w-[370px]">
                        <div className="text-red-500 mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded sm:h-[70px] sm:max-w-[70px]">
                            <div className="text-4xl mb-2">
                                <CgMail />
                            </div>
                        </div>
                        <div className="w-full">
                            <h4 className="mb-1 text-2xl font-semibold text-dark underline dark:text-white">
                                Email Address
                            </h4>
                            <p className="text-base text-body-color font-semibold dark:text-dark-6">
                                <a href="mailto:info@citycatoilpartssupply.com">info@citycatoilpartssupply.com</a>
                            </p>
                            <p className="text-base text-body-color font-semibold dark:text-dark-6">
                                <a href="mailto:citycatoilpartssupply@gmail.com">citycatoilpartssupply@gmail.com</a>
                            </p>
                          
                        </div>
                    </div>
                    <div className="mb-8 flex w-full max-w-[370px]">
                        <div className="text-green-600 mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded sm:h-[70px] sm:max-w-[70px]">
                            <div className="text-4xl mb-2">
                                <FaWhatsapp />
                            </div>
                        </div>
                        <div className="w-full">
                            <h4 className="mb-1 text-2xl font-semibold text-dark underline dark:text-white">
                                WhatsApp
                            </h4>
                            <p className="text-base text-body-color font-semibold dark:text-dark-6">
                                <a href="https://wa.me/919819600149">9819600149</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
 
    
                    <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
                        <div
                            className="relative p-8 bg-white rounded-lg shadow-2xl dark:bg-dark-2 sm:p-12"
                        >
                            <form action="https://formsubmit.co/citycatoilpartssupply@gmail.com" method="POST">
                                <div className="mb-6">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        className="border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none" />
                                </div>
                                <div className="mb-6">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none" />
                                </div>
                                <div className="mb-6">
                                    <input
                                        type="text"
                                        placeholder="Phone"
                                        className="border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none" />
                                </div>
                                <div className="mb-6">
                                    <textarea

                                        placeholder="Your Message"
                                        className="border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-base outline-none"
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="w-full p-3 text-white transition  rounded border-primary bg-rose-600 hover:bg-opacity-90"
                                    >
                                        Send Message
                                    </button>
                                </div>
                            </form>
                            <div>
                                
                                <span className="absolute -right-10 top-[90px] z-[-1]">
                                    <svg
                                        width="34"
                                        height="134"
                                        viewBox="0 0 34 134"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="31.9993"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 1.66665)"
                                            fill="#13C296" />
                                    </svg>
                                </span>
                                <span className="absolute -left-7 -bottom-7 z-[-1]">
                                    <svg
                                        width="107"
                                        height="134"
                                        viewBox="0 0 107 134"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="104.999"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 104.999 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 104.999 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 104.999 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 104.999 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 104.999 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 104.999 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 104.999 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 104.999 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 104.999 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="104.999"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 104.999 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="90.3333"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 90.3333 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="75.6654"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 75.6654 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="31.9993"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 31.9993 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="60.9993"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 60.9993 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="17.3333"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 17.3333 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="132"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 132)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="117.333"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 117.333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="102.667"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 102.667)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="88"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 88)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="73.3333"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 73.3333)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="45"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 45)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="16"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 16)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="59"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 59)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="30.6666"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 30.6666)"
                                            fill="#13C296" />
                                        <circle
                                            cx="46.3333"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 46.3333 1.66665)"
                                            fill="#13C296" />
                                        <circle
                                            cx="2.66536"
                                            cy="1.66665"
                                            r="1.66667"
                                            transform="rotate(180 2.66536 1.66665)"
                                            fill="#13C296" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white rounded-xl overflow-hidden mt-20 shadow-lg">
            <div className="aspect-w-16 aspect-h-10">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.810108658033!2d72.83323402479995!3d18.936049539360138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1dc0d3b42ef%3A0xbb4045be9a75e71f!2sKK%20Chambers%2C%20Dr%20Dadabhai%20Naoroji%20Rd%2C%20Azad%20Maidan%2C%20Fort%2C%20Mumbai%2C%20Maharashtra%20400001!5e0!3m2!1sen!2sin!4v1623145919078!5m2!1sen!2sin" 
                className="w-full h-96" allowFullScreen loading="lazy"></iframe>
            </div>
          </div>
        </section></><Footer></Footer></>

    )
};

export default contact;
