import React, { useState } from 'react';
import { ImFacebook2 } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import logo from '../../assets/logo.png';
const Footer: React.FC = () => {
 

  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <div className='' >
          <img src={logo}/>
         
        </div>
          </a>
          <div className="mt-4 lg:max-w-sm">
            <p className="text-lg font-medium text-gray-600 text-justify">
            City Cat International Limited is a group of companies spread across many industries. We have over 50 years of experience yet we are ALWAYS in pursuit of excellence making us THE ULTIMATE EXPORT COMPANY.
            </p>
         
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 row-gap-8 lg:col-span-3 md:grid-cols-3">
          <div>
            <p className="font-semibold tracking-wide text-gray-800 text-xl ">
              Products
            </p>
            <ul className="mt-2 space-y-2 text-lg font-medium">
            <li className=' hover:scale-110 transition '>
                <a
                  href="/compressors"
                  className="text-gray-600  hover:text-[#E90074]"
                >
                 Compressors
                </a>
              </li>
              <li className=' hover:scale-110 transition '>
                <a
                  href="/valves"
                  className="text-gray-600  hover:text-[#E90074]"
                >
                Valves
                </a>
              </li>
              <li className=' hover:scale-110 transition '>
                <a
                  href="/pumps"
                  className="text-gray-600  hover:text-[#E90074]"
                >
                Pumps     
                </a>
              </li>
              <li className=' hover:scale-110 transition '>
                <a
                  href="/fasterners"
                  className="text-gray-600  hover:text-[#E90074]"
                >
                 Fasterners
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/Flowmeter"
                  className="text-gray-600 hover:text-[#E90074]"
                >
                 Flowmeter
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/Transmitter"
                  className="text-gray-600 hover:text-[#E90074]"
                >
                 Trasmitters
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/boilers"
                  className="text-gray-600 hover:text-[#E90074]"
                >
                  Boilers
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800 text-xl ">
              Quick Links
            </p>
            <ul className="mt-2 space-y-2 text-lg font-medium ">
              <li className=' hover:scale-110 transition'>
                <a
                  href="/"
                  className="text-gray-600  hover:scale-110 transition hover:text-[#E90074]"
                >
                  Home
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/testimonials"
                  className="text-gray-600  hover:scale-110 transition hover:text-[#E90074]"
                >
                 Testimonials
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/"
                  className="text-gray-600 hover:scale-110 transition hover:text-[#E90074]"
                >
                 Download Brochure
                </a>
              </li>
              <li className=' hover:scale-110 transition'>
                <a
                  href="/contact"
                  className="text-gray-600  duration-300 hover:text-deep-purple-accent-400  hover:scale-110 transition hover:text-[#E90074]"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800 text-xl">Contact Us</p>
            <ul className="mt-2 space-y-4 w-96 text-lg font-medium">
              <li>
                <div className='flex flex-col'>
                <p >Address:</p>
                <a
                  href="/"
                  className="text-gray-600  duration-300 hover:text-deep-purple-accent-400  hover:scale-110 transition hover:text-[#E90074]"
                >
                  City Cat International Ltd., 4th floor, K.K Chambers, Purshottamdas Thakur Marg, Fort, Mumbai - 400001, India
                </a>
                </div>
              </li>
            <li>
            <div className='flex flex-col'>
             <p>Email:</p>
             <a
                href="mailto:info@citycatoilpartssupply.com"
                className="text-gray-600  duration-300 hover:text-deep-purple-accent-400  hover:scale-110 transition hover:text-[#E90074]"
             >
                info@citycatoilpartssupply.com
             </a>
             <a
                href="mailto:citycatoilpartssupply@gmail.com"
                className="text-gray-600  duration-300 hover:text-deep-purple-accent-400  hover:scale-110 transition hover:text-[#E90074]"
              >
                citycatoilpartssupply@gmail.com
            </a>
            </div>
            </li>
            <div className='flex flex-col '>
             <p>Pan India No:</p>
             <a
                href="tel:+919819600149"
                className="text-gray-600  duration-300 hover:text-deep-purple-accent-400  hover:scale-110 transition hover:text-[#E90074]"
             >
               +91 9819600149
             </a>
            </div>  
             
            </ul>
          </div>

        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
        <p className="text-base font-medium text-gray-600 ">
        Copyright © 2024 - All rights reserved
        </p>
        <div className="flex items-center mt-4 space-x-4 sm:mt-0">
         <div className='text-2xl text-gray-500 hover:text-blue-700 transition'>  
          <a href='https://www.facebook.com/citycat.oilpartssupply'>
         <ImFacebook2/>
         </a>
         </div>
       
         <div className='text-3xl text-gray-500  hover:text-green-600 transition'>
         <a href='https://wa.me/919819600149'>
         <IoLogoWhatsapp />
         </a>
         </div>
         
         <div className='text-3xl text-gray-500  hover:text-blue-600 transition'>
         <a href='https://www.linkedin.com/in/city-cat-oil-parts-supply-a14a71203/'>
           <FaLinkedin />
           </a>
         </div>
         <div className='text-3xl text-gray-500  hover:text-gray-950 transition'>
         <a href='https://x.com/CityCatOilPart1'>
         <FaXTwitter />
         </a>
         </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
