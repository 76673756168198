import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import rotary from "../../assets/Rotary Screw Compressor.png";
import Reciprocating from "../../assets/Reciprocating Air Compressor.png";
import Centrifugal from "../../assets/Centrifugal Compressor.png";
import high from "../../assets/hsr compressor.jpg";
import Axial from "../../assets/Axial Compressor.png";
import 'aos/dist/aos.css'
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";






const Compressors: React.FC = () => {
   

    return (
      <><Helmet>
        <title>Compressors - City Cat International</title>
        <meta name="description" content="Explore a variety of compressors including Rotary Screw, Reciprocating, Centrifugal, Axial, and High-Speed Reciprocating Compressors at City Cat International." />
        <meta name="keywords" content="Rotary Screw Compressor, Reciprocating Air Compressor, Centrifugal Compressor, Axial Compressor, High-Speed Reciprocating Compressor, City Cat International,compressor manufacturers,industrial air compressors,oil and gas compressors,compressor parts suppliers,compressor servicing" />
        <link rel="icon" type="image/png" href="/path/to/your/favicon.png" />
        <link rel="icon" href={logo} />
      </Helmet><>
      <Navbar></Navbar>

          <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Compressors</h2>
          <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={rotary}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Rotary Screw Compressor</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Rotary screw air compressors operate by trapping air
                    between two meshed rotors and reducing the volume
                    of that trapped air as it moves down through the
                    rotors. This reduction in volume results in compressed
                    air, which can then be used to power air tools,
                    inflate tires, or in numerous other applications
                  </p>

                  
                    <div className="mt-8 flex justify-center">
                      <a
                        href="https://wa.me/+919819600149"
                        className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Quote
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={Reciprocating}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-center text-5xl font-bold sm:text-5xl">Reciprocating Air Compressor</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Reciprocating Air Compressor is a positive displacement
                    air compressor in which air is sucked in a chamber and
                    compressed with the help of a reciprocating piston.
                    It is called as positive displacement compressor because
                    air is first sucked in a chamber and then compression
                    is achieved by decreasing area of the chamber.
                  </p>

                  
                    <div className="mt-8 flex justify-center">
                      <a
                        href="https://wa.me/+919819600149"
                        className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Quote
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={Centrifugal}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Centrifugal Compressor</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Centrifugal compressors are used in a wide variety of
                    applications in chemical plants, refineries, onshore
                    and offshore gas lift and gas injection applications,
                    gas gathering, and in the transmission and storage of
                    natural gas.
                  </p>

              
                    <div className="mt-8 flex justify-center">
                      <a
                        href="https://wa.me/+919819600149"
                        className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Quote
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={Axial}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Axial Compressor</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    An axial-flow compressor compresses its working
                    fluid by first accelerating the fluid and then
                    diffusing it to obtain a pressure increase.
                    The fluid is accelerated by a row of rotating airfoils
                    or blades (the rotor) and diffused by a row of
                    stationary blades (the stator).
                  </p>

                 
                    <div className="mt-8 flex justify-center">
                      <a
                        href="https://wa.me/+919819600149"
                        className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Quote
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={high}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">High Speed Reciprocating Compressor</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A reciprocating compressor is a positivedisplacement
                    machine that uses a piston to
                    compress a gas and deliver it at high pressure.
                    They are often some of the most critical and
                    expensive systems at a production facility, and deserve special attention.
                  </p>

                
                  <div className="mt-8 flex justify-center">
                    <a
                      href="https://wa.me/+919819600149"
                      className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                      Get Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <Footer></Footer></></>
    )
};

export default Compressors;
