import Footer  from '../footer/footer';
import Navbar from '../navbar/navbar'
import React, { useState } from 'react';
import iso from '../../assets/iso.png'
import iso1 from '../../assets/iso.jpg'
import iso2 from '../../assets/iso1.jpg'
import iso3 from '../../assets/iso2.jpg'
import flowmeter from '../../assets/Flowmeter.jpg'
import Transmitter from '../../assets/Transmitter.jpg'
import compressor from '../../assets/compressor.png'
import valves from '../../assets/valves.png'
import pumps from '../../assets/pumps.png'
import fasteners  from '../../assets/fasteners.png'
import boilers  from '../../assets/Boilers.jpg'
import { RiCustomerService2Fill, RiShipLine } from "react-icons/ri";
import { GrUserExpert } from 'react-icons/gr';
import { AiOutlineGlobal } from 'react-icons/ai';
import { HiOutlineBadgeCheck } from 'react-icons/hi';
import { SlBadge } from 'react-icons/sl';
import { Helmet } from 'react-helmet';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';



const Home: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
  
    const openModal = (imageSrc: string) => {
      setModalImageSrc(imageSrc);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setModalImageSrc('');
    };
  
    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };
    return (
      <><Helmet>
        <title>City Cat International - Valves, Compressors, Pumps, and Fasteners</title>
        <meta name="description" content="City Cat International is a premier global manufacturer and exporter of high-quality valves, compressors, pumps, and fasteners for the oil and gas industry." />
        <meta name="keywords" content="Valves, Compressors, Pumps, Fasteners, Oil and Gas Industry, Manufacturer, Exporter,trader,oil and gas equipment,upstream equipment,downstream equipment,oilfield services,gas processing equipment,refinery equipment,oil and gas tools,oil and gas products,oilfield machinery,oil and gas components,industrial equipment suppliers,oilfield supplies,energy industry equipment,industrial hardware,mechanical components,industrial machinery,equipment suppliers,engineering supplies,industrial parts and accessories" />
        <link rel="icon" href={logo} />
      </Helmet><><Navbar></Navbar>

      <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-20">
        <div className="w-full max-w-7xl mx-auto px-4 md:px-6 py-10">
          <div className="group flex max-md:flex-col justify-center gap-1">
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/compressors">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Compressors</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" loading='eager' src={compressor} width="960" height="480" alt="Compressors" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/valves">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Valves</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={valves} width="960" height="480" alt="Valves" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/pumps">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Pumps</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={pumps} width="960" height="480" alt="Pumps" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/fasterners">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Fasteners</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={fasteners} width="960" height="480" alt="Fasterners" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/Flowmeter">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Flowmeter</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={flowmeter} width="960" height="480" alt="flowmeter" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/Transmitter">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Transmitter</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={Transmitter} width="960" height="480" alt="Transmitter" />
            </article>
            <article className="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
              <Link className="absolute inset-0 text-white z-10" to="/boilers">
                <span className="text-center absolute inset-x-0 bottom-0 text-7xl font-bold p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">Boilers</span>
              </Link>
              <img className="object-cover h-72 md:h-[480px] md:w-auto" src={boilers} width="960" height="480" alt="boilers" />
            </article>
          </div>
        </div>
      </main>

          <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-12 mb-32 md:px-56" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h4 className='text-4xl font-sans text-center font-extrabold text-rose-600 pb-7'>About Us</h4>
            <p className='text-2xl text-center text-gray-800'>City Cat International is a premier global manufacturer and exporter of high-quality Valves, Compressors, Pumps, Fasterners ,Flowmeter ,Transmitter and Boilers for the oil and gas industry. With decades of experience and a strong focus on quality, we provide innovative and reliable solutions to meet the changing needs of our customers around the world. Our commitment to excellence drives us to continually improve our products and services, ensuring that we remain at the forefront of the industry. We are dedicated to delivering exceptional performance and value, helping our clients achieve their goals with confidence and efficiency.</p>
          </main>

          <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-12 mb-32 md:px-56" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h4 className='text-4xl font-sans text-center font-extrabold text-rose-600 pb-7  '>Who We Are</h4>
            <p className='text-2xl text-center text-gray-800'>Founded with a vision to bridge the gap between innovation and reliability, City Cat International stands at the forefront of the oil and gas equipment supply chain. Our team of seasoned professionals brings together a wealth of knowledge and experience, ensuring that we provide not only products but also strategic solutions that enhance operational efficiency and safety.</p>
          </main>

          <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-12 mb-32" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h4 className='text-4xl font-sans font-extrabold text-rose-600 pb-8 text-center mb-14'>Why Choose Us</h4>
            <div className="flex flex-wrap justify-center gap-8">
              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <HiOutlineBadgeCheck />
                </div>
                <h2 className="text-xl font-semibold text-center">Product Quality</h2>
              </div>
              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <GrUserExpert />
                </div>
                <h2 className="text-xl font-semibold text-center">Experience and Expertise</h2>
              </div>
              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <AiOutlineGlobal />
                </div>
                <h2 className="text-xl font-semibold text-center">Global Network</h2>
              </div>
              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <RiShipLine />
                </div>
                <h2 className="text-xl font-semibold text-center">Logistics and Supply Chain</h2>
              </div>
              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <RiCustomerService2Fill />
                </div>
                <h2 className="text-xl font-semibold text-center">Customer Service</h2>
              </div>

              <div className="flex flex-col items-center w-40">
                <div className="text-5xl mb-2">
                  <SlBadge />
                </div>
                <h2 className="text-xl font-semibold text-center">Certifications and Accreditations </h2>
              </div>
            </div>
          </main>


          <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h4 className='text-xl font-sans font-bold text-gray-800 pb-8 text-center'>ISO 9001:2015, ISO 14001:2015 & ISO 45001:2018 CERTIFIED COMPANY</h4>
            <img src={iso} className='block mx-auto max-h-32 max-w-56' />
          </main>



          <main className="relative min-h-full flex flex-col justify-center bg-white overflow-hidden mt-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <div className="flex flex-col md:flex-row justify-center md:space-x-4 space-y-4 md:space-y-0">
              <img src={iso1} alt="Image 1" className='h-auto max-w-96 cursor-pointer transition-transform transform hover:scale-105' onClick={() => openModal(iso1)} />
              <img src={iso2} alt="Image 2" className='h-auto max-w-96 cursor-pointer transition-transform transform hover:scale-105' onClick={() => openModal(iso2)} />
              <img src={iso3} alt="Image 3" className='h-auto max-w-96 cursor-pointer transition-transform transform hover:scale-105' onClick={() => openModal(iso3)} />
            </div>

            {isModalOpen && (
              <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50" onClick={handleClickOutside}>
                <div className="relative bg-white p-4 max-w-3xl w-full">
                  <button className="absolute top-2 right-2 text-gray-600" onClick={closeModal}>Close</button>
                  <img id="modal-image" src={modalImageSrc} alt="Modal" className="w-full h-auto" />
                </div>
              </div>
            )}
          </main>


          <Footer></Footer></></>
    );
};

export default Home;
