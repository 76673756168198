import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import control from "../../assets/Control Valves.png";
import Knife from "../../assets/Knife Gate Valve.png";
import piston from "../../assets/Piston Valve.png";
import plug from "../../assets/Plug Valve.png";
import solenoid from "../../assets/Solenoid Valve.png";
import needle from "../../assets/Needle Valve.png";
import Cryogenic from "../../assets/Cryogenic Valve.png";
import check from "../../assets/Check Valve.png";
import pressure from "../../assets/Pressure Seal Valve.png";
import ball from "../../assets/Ball Valve.png";
import butterfly from "../../assets/Butterfly Valve.png";
import globe from "../../assets/Globe Valve.png";
import gate from "../../assets/Gate Valve.png";
import brass from "../../assets/Brass Valve.png";
import safety from "../../assets/safty-valve.jpg";
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";


const Valves: React.FC = () => {
   

    return (
      <><Helmet>
        <title>Valves - High-Quality Industrial Valves</title>
        <meta name="description" content="Explore a wide range of high-quality industrial valves including safety valves, brass valves, gate valves, and more. Get the best quotes for your needs." />
        <meta name="keywords" content="Industrial valves,Safety valves,Brass valves,Gate valves,Globe valves,Cryogenic valves,Ball valves,Pressure seal valves,Check valves,Solenoid valves,Needle valves,Butterfly valves,Plug valves,Piston valves,Control valves,Knife gate valves,Valve suppliers,Valve manufacturers,Valve distributors,High-pressure valves,Flow control valves,Valve types,Valve applications,Valve specifications,Custom valves" />
        <link rel="icon" href={logo} />
      </Helmet><><Navbar></Navbar>

          <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Valves</h2>
          <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={safety}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Safety Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl">
                    A safety valve is designed to open and relieve
                    excess pressure from vessels or equipment
                    and to reclose and prevent the further release of fluid
                    after normal conditions have been restored
                  </p>

                
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={brass}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Brass Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Brass valves are Highly corrosion-resistant,
                    Very low (to no) levels of lead present,
                    exceptionally durable, Malleable
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={gate}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Gate Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A gate valve, also known as a sluice valve, is a valve that
                    opens by lifting a barrier (gate) out of the path of the fluid.
                    Gate valves require very little space along the pipe axis
                    and hardly restrict the flow of fluid
                    when the gate is fully opened.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={globe}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Globe Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A globe valve is a linear motion valve used to stop,
                    start, and regulate the fluid flow. The globe valve
                    disk can be removed entirely from the flow path, or
                    it can completely close the flow path.
                  </p>

              
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>


          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={Cryogenic}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Cryogenic Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A cryogenic valve is generally designed to react to high
                    Pressure which pushes the valve into the open position to
                    allow the gas or other media to flow readily through
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>




          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={ball}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Ball Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A ball valve is a form of quarter-turn valve which uses a hollow,
                    perforated and pivoting ball to control flow through it. It
                    is open when the ball's hole is in line with the flow and closed
                    when it is pivoted 90-degrees by the valve handle.
                  </p>

               
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={pressure}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Pressure Seal Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A pressure seal is a valve design concept that offers
                    distinct advantages when compared to a conventional
                    bolted body-to-bonnet sealing mechanism.
                  </p>

              
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={check}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Check Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Check valves are generally installed in pipelines to prevent
                    backflow. A check valve is basically a one-way valve, in which
                    the flow can run freely one way, but if the flow turns the
                    valve will close to protect the piping, other valves, pumps etc.
                  </p>

               
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={solenoid}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Solenoid Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Solenoid valves are used to close, open, dose,
                    distribute or mix the flow of gas or liquid in a pipe. The
                    specific purpose of a solenoid valve is expressed by its
                    circuit function.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>




          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={needle}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Needle Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Needle valves, sometimes referred to as plunger valves,
                    are regulating valves and enable engineers to finely control
                    and regulate water flow and pressure.
                  </p>

               
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={butterfly}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Butterfly Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A Butterfly valve is a quarter-turn rotational motion
                    valve, that is used to stop, regulate, and start flow. A
                    butterfly valve has a disc which is mounted on a rotating
                    shaft
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>


          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={plug}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Plug Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Plug valves are valves with cylindrical or conically
                    tapered "plugs" which can be rotated inside the valve
                    body to control flow through the valve.
                  </p>

            
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={piston}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Piston Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A piston valve is a device used to control the
                    motion of a fluid along a tube or pipe by means of the
                    linear motion of a piston within a chamber or cylinder.
                  </p>

                
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={control}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Control Valves</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A control valve is a valve used to control fluid flow by
                    varying the size of the flow passage as directed by a
                    signal from a controller. This enables the direct control
                    of flow rate and the consequential control of process
                    quantities such as pressure, temperature, and liquid
                    level.
                  </p>

              
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={Knife}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl text-center">Knife Gate Valve</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Knife gate valves are designed mainly for on-off and
                    isolation services in systems with high content of
                    suspended solids. Knife gate valves are designed to work
                    in some of the most harsh environments, typically
                    having a sharpened blade to cut through heavy liquids.
                    level.
                  </p>

             
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>




          <Footer></Footer></></>
    )
};

export default Valves;
