import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import bolts from "../../assets/Bolts and Setscrews.jpg";
import carniage from "../../assets/Carriage Bolts.jpg";
import eye from "../../assets/Eye Bolts.jpg";
import ubolts from "../../assets/U-Bolts.jpg";
import wood from "../../assets/Wood Screws.jpg";
import machine from "../../assets/Machine Screws.jpg";
import self from "../../assets/Self-Tapping Screws.jpg";
import socket from "../../assets/Socket Screws.jpg";
import grub from "../../assets/Grub Screws.jpg";
import hex from "../../assets/Hex Nuts.jpg";
import lock from "../../assets/Lock Nuts.jpg";
import nylon from "../../assets/Nylon Insert Nuts.jpg";
import shear from "../../assets/Shear Nuts.jpg";
import wing from "../../assets/Wing Nuts.jpg";
import flat from "../../assets/Flat Washers.jpg";
import spring from "../../assets/Spring Washers.jpg";
import cup from "../../assets/Cup Washers.jpg";
import repair from "../../assets/Repair Washers.jpg";
import multi from "../../assets/Multi-Grip Rivets.jpg";
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";


const Pumps: React.FC = () => {
   

    return (
      <><Helmet>
        <title>Fasteners | City Cat Interational</title>
        <meta name="description" content="Explore a wide range of fasteners including bolts, nuts, washers, screws, and more. Get a quote now!" />
        <meta name="keywords" content="fasteners, bolts, nuts, washers, screwsbolts and nuts,screws,rivets,washers,studs,anchors,fastener manufacturers,fastener suppliers,industrial fasteners,fastener distributors" />
        <link rel="icon" href={logo} type="image/x-icon" />
      </Helmet>
      <><Navbar></Navbar>

          <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Fasterners</h2>
          <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={bolts}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Bolts and Setscrews</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Bolts and Setscrews usually feature a hexagonal
                    head with a thread, and can be used either in
                    conjunction with a nut or in a threaded hole.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={carniage}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Carriage Bolts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Also known as Cup Square Hex Bolts, these have a smooth
                    rounded head with a square beneath. They are used for
                    securing metal to wood.
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={eye}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Eye Bolts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These bolts have a circular ring instead of a traditional
                    head and are used to fix rope or chain to a surface.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={ubolts}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">U-Bolts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These bolts are used for attaching round objects
                    such as pipes and tubes to a wall or other surface.
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={wood}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Wood Screws</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Featuring a smooth shank and tapered point, these screws
                    are used in wood and come in different head shapes.
                    Machine
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={machine}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Machine Screws</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Machine Screws are used with a nut or tapped hole.
                    Thread Cutting Machine Screws are also available, which
                    feature a thread cutting point.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={self}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Self-Tapping Screws</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Often referred to as Self-Tappers, these screws
                    are used for in sheet metals. Self-tapping screws
                    tap their own thread.
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={socket}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl text-center font-bold sm:text-5xl">Socket Screws</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These screws feature a smooth shank and an Allen head,
                    and are fastened using an Allen key. Socket screws come
                    with different head shapes, such as Button, Socket Cap and
                    Countersunk.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>

                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={grub}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Grub Screws</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Grub screws are a special type fo screw which usually
                    do not have a head. They are used to prevent movement
                    or rotation between two parts
                  </p>

                  <div className="flex justify-center">
                    <a
                      href="https://wa.me/+919819600149"
                      className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                      Get Quote
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={hex}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Hex Nuts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    A plain, hexagonal nut with an internal thread.
                    These are by far the most common type of nut,
                    and are used in numerous industries and applications
                  </p>

                  <div className="flex justify-center">
                    <a
                      href="https://wa.me/+919819600149"
                      className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                      Get Quote
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={lock}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl ">Lock Nuts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These nuts are used when the nut needs to be locked
                    in place without clamping on to another object.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>

                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={nylon}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Nylon Insert Nuts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Commonly referred to as a Nyloc nut, these hexagonal
                    shaped nuts have a nylon insert which prevents the nut
                    from coming loose due to vibration.
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={shear}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Shear Nuts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These cone shaped nuts feature a hexagonal gripping
                    point which snaps off when the maximum torque has
                    been reached, leaving just the cone-shaped nut which
                    is difficult to remove
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={wing}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Wing Nuts</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Commonly used in applications where the nut needs
                    to be removed often, Wing Nuts feature two external
                    "wings" which allow for manual turning
                  </p>

                   <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={flat}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Flat Washers</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These are the most common type of washer and
                    are used to evenly distribute the load of the bolt,
                    screw or nut as the fastener is tightened.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={spring}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Spring Washers</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These locking washers are designed to stop the bolt,
                    screw or nut from vibrating loose.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={cup}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Cup Washers</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify ">
                    Cup Washers form a cup for the head of the
                    fastener to fit in, creating a flush finish with
                    the fastener head. They are used in conjunction
                    with a wood screw.
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-left">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                  <img
                    alt="Rotary Screw Compressor"
                    src={repair}
                    className="absolute inset-0 h-full w-full object-contain" />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Repair Washers</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    Also commonly referred to as Penny Washers or Fender
                    Washers, they feature a small inside diameter hole and
                    are design to create a greater bearing surface and prevent
                    pull-through
                  </p>
                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>

          <section data-aos="fade-right">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
              <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    alt=""
                    src={multi}
                    className="absolute inset-0 h-full w-full object-contain " />
                </div>

                <div className="lg:py-24">
                  <h2 className="text-5xl font-bold sm:text-5xl">Multi-Grip Rivets</h2>

                  <p className="mt-4 text-gray-600 text-xl text-justify">
                    These rivets are used when joining materials with
                    differing thicknesses, which would normally require multiple
                    rivet sizes. They are very versatile and cost-effective
                  </p>

                  <div className="flex justify-center">
                  <a
                    href="https://wa.me/+919819600149"
                    className="mt-8 inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Get Quote
                  </a>
                </div>
                </div>
              </div>
            </div>
          </section>


          <Footer></Footer></></>
    )
};

export default Pumps;
