import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import Fire from '../../assets/Fire Tube Boiler.png';
import Electric from '../../assets/Electric Steam Boiler.png';
import WaterTube from '../../assets/Water Tube Boiler.png';
import { Helmet } from "react-helmet";
import logo from '../../assets/logo.png';
import React from "react";

const Boilers: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Boilers - City Cat International</title>
                <meta name="description" content="Explore a variety of boilers including Fire Tube, Electric Steam, and Water Tube Boilers at City Cat International." />
                <meta name="keywords" content="Fire Tube Boiler, Electric Steam Boiler, Water Tube Boiler, City Cat International, boiler manufacturers, industrial boilers, oil and gas boilers, boiler parts suppliers, boiler servicing" />
                <link rel="icon" type="image/png" href="/path/to/your/favicon.png" />
                <link rel="icon" href={logo} />
            </Helmet>
            <Navbar />
            <h2 className="text-center text-gray-800 text-4xl lg:text-5xl font-bold leading-tight mt-36 uppercase">Boilers</h2>
            <div className="my-4 mx-auto w-16 h-2 border-4 border-rose-600 mb-20"></div>

            <section data-aos="fade-right">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                            <img
                                alt="Fire Tube Boiler"
                                src={Fire}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-5xl text-center font-bold sm:text-5xl">Fire Tube Boiler</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                A fire tube boiler is a type of boiler in which hot gases from a fire pass through one or more tubes running through a sealed container of water. The heat of the gases is transferred through the walls of the tubes by thermal conduction, heating the water and ultimately creating steam. Fire tube boilers are widely used in the oil and gas industry for their simplicity, efficiency, and ability to handle large volumes of steam.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-left">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
                            <img
                                alt="Electric Steam Boiler"
                                src={Electric}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-center text-5xl font-bold sm:text-5xl">Electric Steam Boiler</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Electric steam boilers are widely used for their efficiency, ease of installation, and operational flexibility. Unlike traditional boilers that rely on fossil fuels, electric steam boilers use electrical energy to generate steam.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="fade-right">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                            <img
                                alt="Water Tube Boiler"
                                src={WaterTube}
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="lg:py-24">
                            <h2 className="text-5xl text-center font-bold sm:text-5xl">Water Tube Boiler</h2>
                            <p className="mt-4 text-gray-600 text-xl text-justify">
                                Water tube boilers are a type of boiler in which water circulates in tubes heated externally by the combustion of fuel. These boilers are particularly suitable for high-pressure applications and are commonly used in the oil and gas industry for their high efficiency, large capacity, and ability to produce high-quality steam.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <a
                                    href="https://wa.me/+919819600149"
                                    className="inline-block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Boilers;
