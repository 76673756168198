import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/components/home/home';
import Contact from '../src/components/contact/contact';
import Testimonials  from '../src/components/testimonials/testimonials';
import Compressors from '../src/components/compressors/compressors';
import Valves from '../src/components/valves/valves';
import Pumps from '../src/components/pumps/pumps';
import Fasterners from './components/Fasterners/Fasterners';
import Boilers from './components/boilers/boilers';
import Transmitter from './components/Transmitter/transmitter';
import Flowmeter  from './components/flowmeter/flowmeter';
import Aos from 'aos';    
import './App.css';
import { useEffect } from 'react';
import React from 'react';

function App() {

  useEffect(() => {
    Aos.init();
  }, []);

 
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/compressors" element={<Compressors />} />
        <Route path="/valves" element={<Valves />} />
        <Route path="/pumps" element={<Pumps/>} />
        <Route path="/fasterners" element={<Fasterners/>} />
        <Route path="/boilers" element={<Boilers/>} />
        <Route path="/Transmitter" element={<Transmitter/>} />
        <Route path="/Flowmeter" element={<Flowmeter/>} />
      </Routes>
    </Router>
  )
}

export default App
